.list-view {
    background: #D1FFF2;
    box-shadow: inset 0px 1px 0px #005159, inset 0px -1px 0px #005159;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    
    & .header {
        font-family: 'Siemens Sans';
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 28px;
        color: #000028;
        
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    }

    & .text {
        font-family: 'Siemens Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #000028;

        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    }
}

.receivers__selection-list {
    /*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
    .mat-list-option {
        display: inline-block !important;
        height: 40px !important;
        font-size: 14px !important;
        background: #D1FFF2;
        box-shadow: inset 0px -1px 0px #007362, inset 0px 1px 0px #007362;
        transition: all .5s ease-out;
        width: 92% !important;
        
        &:hover,
        &:focus {
            background: linear-gradient(90deg, #00FFB9 0%, #00E6DC 100%) !important;
        }
    
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}



.receivers__selection-header {
    flex-direction: row;
    /*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
    .mat-list-option {
        &:hover,
        &:focus {
            background: transparent !important;
            cursor:default;
        }
    }
    /*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
    .mat-list-single-selected-option {
        background: transparent !important;

    }

    .mat-ripple:not(:empty) {
        /*Remove ripple effect on header option*/
        display: none !important;
    }
    
}

// Updated UI based figma essantials. 
// Please checkout https://www.figma.com/file/vRfhW0e02KO3hMVrVMr74l/1.01-Online-UI---Essentials?node-id=2151%3A28454&t=OqGs0MDhv8TREFVr-1

.sie-u-list {
    border: 0;
    border-radius: 0;
    
    background: none;

    li {
        border: 0;
        border-bottom: 1px solid $secondary-colors-deep-blue-30;
        
        vertical-align: middle;
        
        padding: 0px;
        padding-top: 10px;
        padding-bottom: 10px;

        margin-top: 5px;
        margin-bottom: 5px;

        color: white;
        background: none;
    }
}