@import 'variables';

.tag {
    border-radius: 20px;
    padding: 5px 13px 5px 20px;
    cursor: pointer;
    margin-right: 10px;        
    display: inline-flex;
    margin-top: 5px;
    margin-bottom: 5px;
    border-width: 1px;
    border-style: solid;

    .text {
        max-width: 18rem;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        letter-spacing: 0.2px;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
    }

    &.selected {
        background: #00FFB9;
        color: #000028;
    }


    &:hover {
        background: #001F39;
        border: 1px solid #00FFB9;
        color: #00FFB9;
    }

    & .icon {
        padding-left: 12px;
        font-size: 13px;
        font-weight: 700;
    }

 
    &.active {
        border-color: $color-primary;
        color: $color-primary;
    }
}

// Updated UI based figma essantials. 
// Please checkout https://www.figma.com/file/vRfhW0e02KO3hMVrVMr74l/1.01-Online-UI---Essentials?node-id=2151%3A28454&t=OqGs0MDhv8TREFVr-1

.sie-tag {
    padding: 6px 18px;

    font-weight: 800;
    font-size: 14px;
    line-height: 24px;

    cursor: pointer;

    border: 0;
    border-radius: 20px;

    &:focus {
        outline: 1px solid $color-focus;
        outline-offset: 2px;
    }
}

.sie-tag-primary {
    
    color: $color-text-primary;
    background: $interaction-colors-dark-interactive-coral;

    &:hover {
        background: $primary-colors-solids-bold-green;
    }

    &:active {
        background: $interaction-colors-dark-bold-green-90;
    }

    &:disabled {
        color: $secondary-colors-deep-blue-55;
        background: $secondary-colors-deep-blue-80;
    }

}

.sie-tag-secondary {
   
        color: $interaction-colors-dark-interactive-coral;
        background-color: $primary-colors-solids-deep-blue;
    
        border: 1px solid $interaction-colors-dark-interactive-coral;
    
        &:hover {
            color: $primary-colors-solids-bold-green;
            background-color: $interaction-colors-dark-bold-green-12;
            border: 1px solid $primary-colors-solids-bold-green;
            cursor: pointer;
        }
    
        &.active {
            color: $primary-colors-solids-bold-green;
            border: 1px solid $primary-colors-solids-bold-green;
            background-color: $primary-colors-solids-deep-blue;
        }
    
        &:disabled {
            color: $secondary-colors-deep-blue-55;
            background-color: $primary-colors-solids-deep-blue;
            border: 1px solid $secondary-colors-deep-blue-55;
        }
}