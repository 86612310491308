.card {
  // box-shadow: 0px 16px 40px rgb(112 144 176 / 20%);
  // border-radius: 1rem ;
  margin-bottom: 1rem;
  background-color: #000028;
  margin-right: auto;
  margin-left: auto;
  color: #ffffff;

  &-body {
    padding: 2rem 1.5rem;

    @media screen and (max-width: 576px) {
      padding: 2rem 0.5rem;
    }
  }

  &-header {
    border-bottom: 1px solid #ecedee;
    width: 100%;
    margin-right: auto !important;
    margin-left: auto !important;
    background-color: #000028;
    font-weight: 600 !important;
    margin: 0px 0px 4px !important;
    padding: 1.2rem 2rem;
  }
}

.card-header:first-child {
  border-radius: calc(1rem - 1px) calc(1rem - 1px) 0 0;
}
