@import 'variables';

.heading {
    font-weight: 900;
    color: $color-text-primary;

    &--6 {
        font-size: 18px;
        line-height: 28px;  
    }

    &--5 {
        font-size: 24px;
       line-height: 32px;
    }

    
    &--4 {
        font-size: 32px;
        line-height: 38px;
    }

    &--3 {
        font-size: 36px;
        line-height: 46px;
    }

    &--2 {
        font-size: 40px;
        line-height: 52px;
    }
    
}