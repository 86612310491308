@import "variables";
.link {
    color: $primary-colors-solids-white;
    font-family: 'Siemens Sans';
    font-style: normal;
    line-height: 28px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: #005159;
        text-decoration: underline;
    }
}