@import 'variables';

.toast {
    box-shadow: 0px 0px 2px rgb(0 0 40 / 10%), 0px 4px 8px rgb(0 0 40 / 10%), 0px 12px 18px rgb(0 0 40 / 10%);
    border-radius: 4px;
    width: 422px;
    background: $interaction-colors-dark-bold-green-12;

    
}

.btn-close {
    color: $primary-colors-solids-deep-blue;
    width: 3px;
    height: 3px;
}

.toast-header {
    height: 20px;
    width: 20px;
    display: block;
    border-bottom: 0px;
    position: absolute;
    right: 28px;
    background-color: transparent;
    padding: 0px;
    margin-top: 16px;

    button {
        cursor: pointer;
        padding: 0;
        border: 0;
        background: transparent;
        svg {
            cursor: pointer;
            width: 16px;
            height: 16px;
            fill:$primary-colors-solids-white;
        }
    }
}


.toast-body {
    display: flex;
    padding: 0px;

    &.success {
        fill: $feedback-colors-dark-green;
    }

    &.error {
        fill: $feedback-colors-dark-red;
    }

    &.warning  {
        fill: $feedback-colors-dark-yellow;
    } 

    &.info  {
        fill: $primary-colors-solids-white;
    } 

    .icon {
        margin: 16px 0px 16px 30px;
        svg {
            width: 27px;
            height: 27px;
        }
    }

    .message {
        margin: 18px 28px 16px 26px;
        color: $primary-colors-solids-white;
    }
}