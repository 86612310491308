.form-field {
    width: 100%;
    display: inline-block;
    position: relative;
    text-align: left;
    font-size: inherit;
    font-weight: 400;
    line-height: 1.125;
    letter-spacing: normal
}

.form-field-wrapper {
    padding-bottom: 1.34375em;
    position: relative;
}

.form-field-type-mat-select:not(.form-field-disabled) .form-field-flex {
    cursor: pointer;
}


.form-field-appearance-fill .form-field-flex {
    border-radius: 4px 4px 0 0;
    padding: 0.3em 0.75em 0 0.75em;
}


.form-field-appearance-fill .form-field-flex {
    background-color: rgba(0, 0, 0, 0.04);
}



.form-field-flex {
    display: inline-flex;
    align-items: baseline;
    box-sizing: border-box;
    width: 100%;
}


.form-field-underline {
    position: absolute;
    width: 100%;
    pointer-events: none;
    transform: scale3d(1, 1.0001, 1);
}

.form-field-underline {
    bottom: 1.34375em;
}

.mat-form-field-appearance-fill .form-field-ripple {
    bottom: 0;
    height: 2px;
}

.form-field-ripple {
    position: absolute;
    left: 0;
    width: 100%;
    transform-origin: 50%;
    transform: scaleX(0.5);
    opacity: 0;
    transition: background-color 300ms cubic-bezier(0.55, 0, 0.55, 0.2);
}
.form-field-ripple {
    background-color: rgba(0, 0, 0, 0.87);
}

.m-form-field-appearance-fill .form-field-infix {
    padding: 0.25em 0 0.75em 0;
}

.form-field-infix {
    display: block;
    position: relative;
    flex: auto;
    min-width: 0;
    width: 180px;
}
.form-field-infix {
    padding: 0.5em 0;
    border-top: 0.84375em solid transparent;
}


.form-field-label-wrapper {
    position: absolute;
    left: 0;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}
.form-field-label-wrapper {
    top: -0.84375em;
    padding-top: 0.84375em;
}

.form-field-type-mat-select .form-field-label {
    width: calc(100% - 18px);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.form-field-empty.mat-form-field-label, .form-field-can-float.form-field-should-float .form-field-label {
    display: block;
}

.form-field-appearance-fill .form-field-label {
    top: 1.09375em;
    margin-top: -0.5em;
}

.form-field-label {
    position: absolute;
    left: 0;
    font: inherit;
    pointer-events: none;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transform-origin: 0 0;
    transition: transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1),color 400ms cubic-bezier(0.25, 0.8, 0.25, 1),width 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    display: none;
}

.form-field-label {
    color: rgba(0, 0, 0, 0.6);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: #EBF7F8 !important;
    box-shadow: inset 0px -1px 0px #4c4c68;
}