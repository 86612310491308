.primary-button,
.secondary-button,
.tertiary-button,
.hero-button {
    height: 40px;
    min-width: 166px;
    font-weight: 900;
    font-size: 18px;
    line-height: 23px;

    &:focus {
        outline: 1px solid #199FFF !important;
        outline-offset: 2px;
      }

    span {
        font-weight: 900;
        font-size: 14px;
        line-height: 28px;
    }

    svg {
        fill: white;
        margin-right: 5px;
        margin-bottom: 2px;
        height: 18px;
        width: 18px;
    }

}


.primary-button {
    background: #007993;
    color: white;

    &:hover {
        background: #196269;
        color: white;
    }

    &.delete {
        background: #B81E31;
    }
}

.hero-button {
    background: linear-gradient(90deg, #00FFB9 0%, #00E6DC 100%);

    span {
        font-style: normal;
        color: #000028;
    }

    &:hover,
    &:active {
        background: #C2FFEE;
    }
}

.secondary-button {
    background: white;
    color: #007993;
    border: 1px solid #007993 !important;

    &:hover,
    &:active {
        background: #C2FFEE;
        border: 1px solid #005159;
        color: #005159
    }
}

.tertiary-button {
    color:#007993;

    &:hover {
        background: #C2FFEE;
        color: #005159;
    }
}

.icon-button {
    background: linear-gradient(90deg, #00D7A0 0%, #00BEDC 100%);
    border-radius: 32px;
    width: 64px;
    height: 64px;
}

// Updated UI based figma essantials. 
// Please checkout https://www.figma.com/file/vRfhW0e02KO3hMVrVMr74l/1.01-Online-UI---Essentials?node-id=2151%3A28454&t=OqGs0MDhv8TREFVr-1

.sie-button {
    padding: 8px 32px;

    font-weight: 900;
    font-size: 15px;
    line-height: 24px;

    border: 0;

    &:focus {
        outline: 1px solid $color-focus;
        outline-offset: 2px;
    }
}

.sie-button-hero {
    color: $color-text-primary;
    background: $primary-gradient-colors-bold-dynamic-petrol;

    &:hover {
        background: $interaction-colors-dark-light-green;
    }

    &:active {
        background: $interaction-colors-dark-bold-green-90;
    }

    &:disabled {
        color: $secondary-colors-deep-blue-55;
        background: $secondary-colors-deep-blue-80;
    }
}

.sie-button-primary {
    color: $color-text-primary;
    background: $interaction-colors-dark-interactive-coral;

    &:hover {
        background: $primary-colors-solids-bold-green;
    }

    &:active {
        background: $interaction-colors-dark-bold-green-90;
    }

    &:disabled {
        color: $secondary-colors-deep-blue-55;
        background: $secondary-colors-deep-blue-80;
    }
}

.sie-button-secondary {
    color: $interaction-colors-dark-interactive-coral;
    background-color: $primary-colors-solids-deep-blue;

    border: 1px solid $interaction-colors-dark-interactive-coral;

    &:hover {
        color: $primary-colors-solids-bold-green;
        background-color: $interaction-colors-dark-bold-green-12;
        border: 1px solid $primary-colors-solids-bold-green;
    }

    &:active {
        color: $primary-colors-solids-bold-green;
        border: 1px solid $primary-colors-solids-bold-green;
        background-color: $primary-colors-solids-deep-blue;
    }

    &:disabled {
        color: $secondary-colors-deep-blue-55;
        background-color: $primary-colors-solids-deep-blue;
        border: 1px solid $secondary-colors-deep-blue-55;
    }
}

.sie-button-teriary {
    color: $interaction-colors-dark-interactive-coral;
    background-color: $primary-colors-solids-deep-blue;

    &:hover {
        color: $primary-colors-solids-bold-green;
        background-color: $interaction-colors-dark-bold-green-12;
    }

    &:active {
        color: $primary-colors-solids-bold-green;
        background-color: $primary-colors-solids-deep-blue;
    }

    &:disabled {
        color: $secondary-colors-deep-blue-55;
        background-color: $primary-colors-solids-deep-blue;
        border: 0;
    }
}

.sie-select {
    border: 0;
    border-radius: 0;

    height: 56px;

    color: $secondary-colors-deep-blue-30;
    background-color: $interaction-colors-dark-coral-12;

    border-bottom: 1px solid $secondary-colors-deep-blue-30;

    &:focus {
        outline: 1px solid $color-focus;
        outline-offset: 2px;

        background-color: $interaction-colors-dark-bold-green-12;
        border-bottom: 1px solid $primary-colors-solids-bold-green;
    }

    option {
        &:hover {
            background-color: $color-green-primary900;
        }
    }
}

.sie-accordion {
    color: $secondary-colors-deep-blue-30;
    background-color: $interaction-colors-dark-coral-12;


    .sie-accordion-item {
        color: $secondary-colors-deep-blue-30;
        background-color: $interaction-colors-dark-coral-12;


        border: 0;
        border-radius: 0;

        border-top: 1px solid $secondary-colors-deep-blue-30;
        border-bottom: 1px solid $secondary-colors-deep-blue-30;
    }

    .sie-accordion-header .sie-accordion-button {
        color: $secondary-colors-deep-blue-30;
        background-color: $interaction-colors-dark-coral-12;

        border: 0;
        border-radius: 0;
        
        box-shadow: none;

        &:after {
            color: $secondary-colors-deep-blue-30;
        }
    }
}