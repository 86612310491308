@import 'variables';

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-radio-outer-circle {
    border-color: $color-input-shadow;
    background-color: $secondary-green-200;
    
}


/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $secondary-green-800 !important;
    background-color: $secondary-green-500;
}

.mat-mdc-radio-button.mat-accent .mat-radio-inner-circle, 
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), 
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, 
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: $secondary-green-800;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, 
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $secondary-green-800;
}


.mat-mdc-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, 
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background: $secondary-green-100;
}

// Updated UI based figma essantials. 
// Please checkout https://www.figma.com/file/vRfhW0e02KO3hMVrVMr74l/1.01-Online-UI---Essentials?node-id=2151%3A28454&t=OqGs0MDhv8TREFVr-1

.sie-checkbox {

    display: flex;
    flex-direction: row;
    align-items: flex-end;

    input {
        border: 0;
        border: 2px solid $secondary-colors-deep-blue-30;
        border-radius: 0;
        background-color: $secondary-colors-deep-blue-85;

        width: 24px;
        height: 24px;

        margin-right: 5px;

        &:focus {
            outline: 1px solid $color-focus;
            outline-offset: 2px;
        }

        &:hover {
            border: 2px solid $primary-colors-solids-bold-green;
        }

        &:checked {
            border: 2px solid $secondary-colors-deep-blue-30;
            color: $primary-colors-solids-bold-green;
        }

    }

    label {
        color: $secondary-colors-deep-blue-30;
    }
}