@import "variables";


.ngx-datatable.material {
    border-radius: 9px;
    box-shadow: 0px 2px 5px 0px #4C4C68 !important;
    // box-shadow: none;
    // border: 1px solid #e1e1e1;
}


.ngx-datatable.material .datatable-header {
    // background-color: whitesmoke;
    color: #FFFFFF;
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
    // background-color: whitesmoke;
    // padding: 0.5rem 0.5rem !important;
    color: #FFFFFF;
}


.datatable-header-cell-label {
    font-size: 13px;
    color: $color-text-primary;
    font-weight: 400;
}

.table-icon {
    width: 16px;
    height: 16px;
    cursor: pointer !important;
    fill: #00CCCC;

    &.remove {
        fill: #B81E31;
    }

    &.save {
        fill:#018136;
        width: 26px;
        height: 26px;
    }
}


.ngx-datatable.material .datatable-body .datatable-row-wrapper:not(:last-of-type) .datatable-body-cell {
    border-bottom: 1px solid #4C4C68 !important;
}


.edit-icon-container {
    svg {
        margin-right: 1rem;
    }
}

.ngx-datatable.no-shadow {
    box-shadow: none !important;
    border-radius: 0px !important;
}


.ngx-datatable .datatable-header {
    // border-bottom: 1px solid #4C4C68;
    border-bottom: 1px solid #B3B3BE;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #001F39;
    color: #FFFFFF;
    height: 100%!important;
    
}


// .ngx-datatable .datatable-footer {
//     // border-top: 1px solid #4C4C68;
//     border-bottom-right-radius: 10px;
//     border-bottom-left-radius: 10px;
//     background-color: #001f39;
//     color: #FFFFFF;
//     height: 50%!important;
    
// }

.small-table .datatable-row-left{
    background-color: #001F39  !important;
    backface-visibility: hidden;
    // cursor: pointer;
}

.small-table .datatable-row-group.datatable-row-left{
    background-color: #001F39  !important;
    backface-visibility: hidden;
    // cursor: pointer;
}

.small-table .datatable-header-cell{
    padding: 0.6rem 0.4rem !important;
    border-right: 1px solid  #B3B3BE !important
}


.small-table .datatable-body-cell {
    padding: 0.6rem 0.4rem !important;
    border: 1px solid  #001f39 !important
}

.small-table .datatable-body .datatable-body-cell-label{
    color: #FFFFFF !important;
    font-size: 13px !important;
}

.ngx-datatable .datatable-body{
    color: #FFFFFF !important;
}

.ngx-datatable .datatable-header-cell-label {
    font-weight: 600 !important;
    color: #FFFFFF !important;
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
    white-space: pre-wrap !important;
}

// .ngx-datatable .datatable-body{
//     border: 1px solid  #001f39 !important;
//     border-bottom-left-radius: 10px;
//     border-bottom-right-radius: 10px;
// }

/* FILTER HEADER */

.ngx-datatable.filter-header {
   /* border-radius: 9px; */
   box-shadow: 0px 5px 5px 0px #4C4C68 !important;
   /* border: 1px solid #efefef; */     
   border: 0px !important;
   border-bottom-left-radius: 9px;
   border-bottom-right-radius: 9px;
   border-top-left-radius: 0px;
   border-top-right-radius: 0px;
}


.table-filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.datatable-footer{
    color: #FFFFFF;
}
