$color-focus: #1491EB;
$focus-border: 1px solid $color-focus;

// PRIMARY COLORS

/* Solid */
$primary-colors-solids-white: #FFFFFF;
$primary-colors-solids-deep-blue: #000028;
$primary-colors-solids-light-sand: #F3F3F0;
$primary-colors-solids-sie-petrol: #009999;
$primary-colors-solids-bold-blue: #00e6dc;
$primary-colors-solids-bold-green: #00FFB9;

/* Gradient */
$primary-gradient-colors-bold-dynamic-petrol: linear-gradient(90deg, $primary-colors-solids-bold-green 0%, $primary-colors-solids-bold-blue 100%);;
$primary-gradient-colors-deep-blue-petrol:  linear-gradient(180deg, $primary-colors-solids-deep-blue 0%, $primary-colors-solids-sie-petrol 100%);

// INTERACTION COLORS
$interaction-colors-light-interactive-blue: #007993;
$interaction-colors-light-teal: #005159;
$interaction-colors-light-teal-90: #196269;
$interaction-colors-light-teal-84: #296D74;
$interaction-colors-light-teal-80: #33747A;
$interaction-colors-light-teal-74: #427E84;
$interaction-colors-light-teal-64: #5C9095;
$interaction-colors-light-light-red: #FCCCD7;
$interaction-colors-light-cyan-8: #EBF7F8;
$interaction-colors-light-bold-green-18: #D1FFF2;
$interaction-colors-light-light-green: #62EEC7;

$interaction-colors-dark-coral-8: #001034;
$interaction-colors-dark-coral-12: #00183B;
$interaction-colors-dark-coral-20: #002949;
$interaction-colors-dark-coral-70: #008F9B;
$interaction-colors-dark-bold-green-12: #001F39;
$interaction-colors-dark-bold-green-90: #00E5AA;
$interaction-colors-dark-interactive-coral: #00CCCC;
$interaction-colors-dark-light-green: #C5FFEF;
$interaction-colors-dark-dark-red: #331131;
$interaction-focus-border: #1491EB;

// SECONDARY COLORS
$secondary-colors-deep-blue-8: #EBEBEE;
$secondary-colors-deep-blue-10: #E5E5E9;
$secondary-colors-deep-blue-30: #B3B3BE;
$secondary-colors-deep-blue-40: #9999A9;
$secondary-colors-deep-blue-50: #7D8099;
$secondary-colors-deep-blue-55: #737389;
$secondary-colors-deep-blue-70: #4C4C68;
$secondary-colors-deep-blue-80: #333353;
$secondary-colors-deep-blue-85: #262648;


// FEEDBACK COLORS
$feedback-colors-dark-red: #FF2640;
$feedback-colors-dark-red-text: #FF7687;
$feedback-colors-dark-green: #01D65A;
$feedback-colors-dark-yellow: #FFD732;
$feedback-colors-dark-orange: #FF9000;
$feedback-colors-dark-blue: #00BEDC;

$feedback-colors-light-red: #D72339;
$feedback-colors-light-red-text: #B81E31;
$feedback-colors-light-green: #01893A;
$feedback-colors-light-green-text:#018136;
$feedback-colors-light-orange: #E96401;
$feedback-colors-light-blue: #007EB1;
$feedback-colors-light-yellow: #E9C32A;

$color-background: #F3F3F0;
$color-header-border: #f4f2f2;
$color-header-user-nav: #E5E5E7;

$color-text-primary: #000028;
$color-background: #F3F3F0;
$color-header-border: #f4f2f2;
$color-header-user-nav: #E5E5E7;

$color-primary:#009999;

$color-secondary: #00CCCC;
$color-tertiary: #009999;


$color-green-primary900: #005159;

$secondary-green-100:#EBF7F8;
$secondary-green-200: #E0F1F4;
$secondary-green-400: #D1FFF2;
$secondary-green-500: #C2FFEE;
$secondary-green-800: #005159;




//Input
$color-input-shadow: #4c4c68;
$color-input-shadow-hover: #00D7A0;


// Feedback Colors

$feedback-red-text: #B81E31;
$feedback-red:  #D72339





