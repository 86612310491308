
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label {
    opacity: 1 !important;
    font-size: 14px;
    font-weight: 900;
    line-height: 28px;
    
    &:hover {
        background: #D1FFF2;
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label, .mat-mdc-tab-link {
    color: #000028;
}


.mat-mdc-tab-group.mat-primary .mat-ink-bar, .mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #005159;;
}


/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-body-wrapper {
    margin-top: 2rem;
}

// Updated UI based figma essantials. 
// Please checkout https://www.figma.com/file/vRfhW0e02KO3hMVrVMr74l/1.01-Online-UI---Essentials?node-id=2151%3A28454&t=OqGs0MDhv8TREFVr-1

.sie-tab {
    display: flex;

    color: white;
    background-color: $color-text-primary;
    box-shadow: inset 0px -2px 0px $secondary-colors-deep-blue-55;
}

.sie-tab-item {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 30px;
    font-style: normal;
    font-weight: 900;
    font-size: 15px;

    cursor: pointer;

    &:focus {
        outline: 1px solid $color-focus;
        outline-offset: 2px;
    }
    
    &:hover {
        background: $interaction-colors-dark-bold-green-12;
        box-shadow: inset 0px -2px 0px $primary-colors-solids-bold-green;
    }

    &.active {
        background: $interaction-colors-dark-bold-green-12;
        box-shadow: inset 0px -2px 0px $primary-colors-solids-bold-green;
    }

    &:disabled {
        color: $secondary-colors-deep-blue-55;
    }

}