.mat-mdc-dialog-title {
    color: #000028;
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 24px !important;
    line-height: 32px !important;
}

.mat-mdc-dialog-content {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #000028;
    margin-bottom: 14px !important;
}



.mat-mdc-dialog-actions {
    justify-content: right;
}


@media (max-width: 425px){
    .mat-mdc-button {
        width: 100% !important;
        margin-bottom: 1rem !important;
        margin-left: 0px !important;
    }
}