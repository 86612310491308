@import 'sass/input';
@import 'sass/form';
@import 'sass/heading';
@import 'sass/checkbox';
@import 'sass/table';
@import 'sass/button';
@import 'sass/tabs';
@import 'sass/toast';
@import 'sass/tags';
@import 'sass/card';
@import 'sass/dialog';
@import 'sass/link';
@import 'sass/list';

/* 
** Fonts
*/

@font-face {
  font-family: 'Siemens Sans';
  src: url('./assets/fonts/SiemensSans_Prof_Bold.woff2') format('woff2'), url('./assets/fonts/SiemensSans_Prof_Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Siemens Sans';
  src: url('./assets/fonts/SiemensSans_Prof_Black.woff2') format('woff2'), url('./assets/fonts/SiemensSans_Prof_Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Siemens Sans';
  src: url('./assets/fonts/SiemensSans_Prof_Roman.woff2') format('woff2'), url('./assets/fonts/SiemensSans_Prof_Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Siemens Sans';
  src: url('./assets/fonts/SiemensSans_Prof_Italic.woff2') format('woff2'),
    url('./assets/fonts/SiemensSans_Prof_Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Siemens Sans';
  src: url('./assets/fonts/SiemensSans_Prof_BlackItalic.woff2') format('woff2'),
    url('./assets/fonts/SiemensSans_Prof_BlackItalic.woff') format('woff');
  // font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Siemens Sans';
  src: url('./assets/fonts/SiemensSans_Prof_BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/SiemensSans_Prof_BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Siemens Sans, sans-serif !important;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #000028;
}

$interaction-colors-light-bold-green-18: #d1fff2;
$interaction-colors-light-cyan-8: #ebf7f8;
$interaction-colors-light-light-green: #62eec7;

// body,
// app-root,
// app-root>* {
//   display: flex;
//   flex: auto;
//   flex-direction: column;
// }

// body {
//   font-size: 1.1rem;
//   font-weight: 400;
//   line-height: 1.5;
//   color: $siemens-deep-blue-900;
//   text-align: left;
//   background-color: $siemens-white;

//   h6 {
//     line-height: 28px;
//     font-size: 1.25rem;
//     font-weight: bold;
//   }
//   input[type=tel]{
//     background-color: $interaction-colors-light-cyan-8!important;
//   }

//   input[type=text],
//   [type=email],
//   [type=password] {
//     color: $siemens-deep-blue-900;
//     background-color: $interaction-colors-light-cyan-8;

//     border: 0;
//     border-radius: 0;
//     border-bottom: 1px solid $siemens-deep-blue-300;

//     &:focus {
//       outline: 1px solid $siemens-focus;
//       outline-offset: 2px;

//       color: $siemens-deep-blue-900;
//       background-color: $interaction-colors-light-bold-green-18;
//       border-bottom: 1px solid $siemens-bold-green;
//     }

//     &:hover {
//       background-color: $interaction-colors-light-bold-green-18;
//       color: $siemens-deep-blue-900;
//       border-bottom: 1px solid $siemens-teal;
//     }

//   }

//   input[type=checkbox] {

//         border: 0;
//         border: 2px solid $siemens-deep-blue-700;
//         border-radius: 0!important;
//         background-color: $interaction-colors-light-cyan-8;

//         width: 20px!important;
//         height: 20px!important;

//         margin-right: 5px;
//         margin-top: 0.15em;

//         &:focus {
//             outline: 1px solid $siemens-focus;
//             outline-offset: 2px;
//         }

//         &:hover {
//             border: 2px solid $siemens-teal;
//             background-color: $interaction-colors-light-bold-green-18;
//         }

//         &:checked {
//             border: 2px solid $siemens-teal !important;
//             background-color: $interaction-colors-light-bold-green-18 !important;
//             background-image: url("data:image/svg+xml, %3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.568 0.445312L16.2321 1.55471L7.55544 14.5697L0.292908 7.30712L1.70712 5.89291L7.24401 11.429L14.568 0.445312Z' fill='%23005159'/%3E%3C/svg%3E") !important;

//         }

//      &.form-check-input{
//         inline-size: 20px!important;
//         block-size: 20px!important;
//      }

//     &.ng-invalid.ng-touched {
//       background: #fcccd7;
//     }
//   }

// }

si-select {
  block-size: 56px !important;
  // color: $siemens-deep-blue-900;
  background-color: $interaction-colors-light-cyan-8;

  border: 0;
  border-radius: 0;
  // border-bottom: 1px solid $siemens-deep-blue-300!important;

  &:focus {
    // outline: 1px solid $siemens-focus;
    outline-offset: 2px;

    // color: $siemens-teal;
    background-color: $interaction-colors-light-bold-green-18;
    // border-bottom: 1px solid $siemens-bold-green;
  }

  &:hover {
    background-color: $interaction-colors-light-bold-green-18;
    // color: $siemens-deep-blue-900;

    // border-bottom: 1px solid $siemens-teal;
  }
}

si-select-list {
  .cdk-listbox {
    background: $interaction-colors-light-bold-green-18;
  }
}

si-select-input {
  font-weight: 400 !important;
  padding: 0.375rem 1rem;

  &.ps-4 {
    padding-inline-start: 1rem !important;
  }

  &.pe-5 {
    padding-inline-end: 1rem !important;
  }
}

si-select-option-row {
  font-size: 1.1rem;

  &.dropdown-item.active {
    background-color: $interaction-colors-light-light-green !important;
  }

  &:hover {
    background-color: $interaction-colors-light-light-green !important;
    cursor: pointer;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: 56px;
  padding: 0.375rem 1rem;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4c4c68;
  background-color: $interaction-colors-light-cyan-8;
  background-clip: padding-box;
  border: 0 solid #4c4c68;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0.15s ease-in-out;
}

.captcha-form {
  height: auto;
  background-color: #000028;
  // padding: 0;
  box-shadow: none;

  display: flex;
  justify-content: flex-end;
}

.form-item-label-wrapper {
  width: 20%;
  // text-wrap: nowrap;
  flex: none !important;
  .form-label {
    font-size: 1rem;
  }
}

.register-form .overflow-auto {
  overflow: visible !important;
}

router-outlet {
  display: none;
}

main {
  padding-top: 100px;
}

header {
  color: #000028 !important;
  background-color: #fff !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  // padding: 24px 240px 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  top: 0 !important;
  width: 100% !important;
  z-index: 1000 !important;
  display: flex !important;
  justify-content: flex-start !important;
  flex-direction: column !important;

  .collapse {
    margin-right: auto;
    margin-left: auto;
    padding: 24px 0 !important;

    @media screen and (min-width: 1400px) {
      width: 1320px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1399px) {
      width: 1140px;
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      width: 960px;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      width: 720px;
    }
    @media screen and (min-width: 576px) and (max-width: 767px) {
      width: 540px;
    }
  }
}

// footer{
//     width: 100%;
//     padding-top: 33px;
//     padding-bottom: 33px;
//     color: #000028 !important;
//   background-color: #fff !important;
//   font-size: 1.1rem !important;
//   font-weight: 400 !important;

//   .container-fluid{

//     @media screen and (min-width: 1400px) {
//       width: 1320px;
//     }
//     @media screen and (min-width: 1200px) and (max-width:1399px){
//       width: 1140px;
//     }
//     @media screen and (min-width: 992px) and (max-width:1199px){
//       width: 960px;
//     }

//     @media screen and (min-width: 768px) and (max-width:991px){
//         width: 720px;
//     }
//     @media screen and (min-width: 576px) and (max-width:767px){
//         width: 540px;
//     }
//   }
// }

si-form-item {
  margin-block-end: 3% !important;
}

.btn {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  padding: 8px 32px;
}

.iti {
  display: block !important;
}

#phone::placeholder {
  color: white;
  opacity: 1; /* Firefox */
}

#phone::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: white;
}

ul {
  background-color: #00183b !important;
  color: white;
}

.form-control:disabled {
  background-color: $interaction-colors-dark-coral-12 !important;
  border-bottom: 0px !important;
  color: $secondary-colors-deep-blue-55 !important;
}

.template {
  color: #00cccc;
  cursor: pointer;
  // display: block;
  text-decoration: underline;
  font-size: 16px;
}

.tooltip-inner{
  background-color: #F3F3F0;
  color: #000028;
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before{
  border-bottom-color: #F3F3F0;
}

::-ms-reveal {
  display: none;
}

