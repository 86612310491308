@import 'variables';


$themes: (
    dark:(
        primary-background-color: $primary-colors-solids-deep-blue,
        primary-text-color: $primary-colors-solids-white,
        secondary-text-color: $secondary-colors-deep-blue-30,
        header-hover-border-color: $primary-colors-solids-bold-green,
        header-bottom-color: $interaction-colors-dark-coral-8,
        mobile-menu-background-color: $interaction-colors-dark-coral-8, 
        sie-logo-icon-color:  $primary-colors-solids-white,
        intra-icon-color:$primary-colors-solids-white,
        input-background: $interaction-colors-dark-coral-12,
        input-floating-label-color: $secondary-colors-deep-blue-30, 
        input-border-color: $secondary-colors-deep-blue-30,
        input-hover-background: $interaction-colors-dark-bold-green-12,
        input-hover-border-color: $primary-colors-solids-bold-green,
        input-hover-text-color: $primary-colors-solids-white,
        input-hover-label-color: $primary-colors-solids-bold-green,
        input-invalid-background-color: $interaction-colors-dark-dark-red,
        input-invalid-border-color: $feedback-colors-dark-red,
        input-invalid-text-color: $feedback-colors-dark-red-text,
        input-disabled-border-color: $interaction-colors-dark-coral-12,
        input-disabled-text-color: $secondary-colors-deep-blue-55,
        checkbox-border-color: $secondary-colors-deep-blue-30,
        checkbox-background-color:$secondary-colors-deep-blue-85,
        checkbox-second-border-color:$primary-colors-solids-bold-green,
        checkbox-second-background-color:$interaction-colors-dark-bold-green-12,
        hero-border-color: $primary-colors-solids-deep-blue,
        hero-hover-background-color: $interaction-colors-dark-light-green,
        hero-active-background-color: $interaction-colors-dark-bold-green-90,
        primary-button-background-color: $interaction-colors-dark-interactive-coral,
        primary-button-color: $primary-colors-solids-deep-blue,
        primary-button-hover-background-color: $primary-colors-solids-bold-green,
        secondary-button-color: $interaction-colors-dark-interactive-coral,
        secondary-button-hover-background-color: $interaction-colors-dark-bold-green-12,
        secondary-button-hover-color: $primary-colors-solids-bold-green,
        tertiary-button-color: $interaction-colors-dark-interactive-coral,
        tertiary-button-hover-color: $primary-colors-solids-bold-green,
        tertiary-button-hover-background-color:$interaction-colors-dark-bold-green-12,
        datepicker-background-color: $interaction-colors-dark-coral-12,
        datepicker-hover-background-color: $primary-colors-solids-bold-green,
        datepicker-selected-background-color: $interaction-colors-dark-coral-70,
        datepicker-selected-text-color: $primary-colors-solids-deep-blue,
        datepicker-header-divider-color: $secondary-colors-deep-blue-55,
        select-background-color: $interaction-colors-dark-bold-green-12,
        select-panel-hover-background-color: $interaction-colors-light-teal,
        file-upload-color: $interaction-colors-dark-coral-70,
        file-upload-table-row-color: $interaction-colors-dark-coral-70,
        file-upload-table-background-color: $interaction-colors-dark-coral-8,
        remove-icon-color: $feedback-colors-dark-red,
        table-border-color: $secondary-colors-deep-blue-70,
        table-header-border-color: $primary-colors-solids-white,
        table-header-text-color: $primary-colors-solids-white,
        table-header-background-color: $interaction-colors-dark-bold-green-12,
        table-body-border-color: $secondary-colors-deep-blue-70,
        table-icon-color: $primary-colors-solids-white,
        table-pager-default-color:$interaction-colors-dark-interactive-coral,
        table-pager-disabled-color: $secondary-colors-deep-blue-55,
        table-pager-active-background-color: $primary-colors-solids-bold-green,
        table-pager-hover-background-color: $interaction-colors-dark-light-green,
        link-color: $interaction-colors-dark-interactive-coral,
        link-hover-color:$primary-colors-solids-bold-green,
        toast-success:$feedback-colors-dark-green,
        toast-error:$feedback-colors-dark-red,
        toast-warning:$feedback-colors-dark-orange,
        toast-info: $feedback-colors-dark-blue,
        card-background-color: $interaction-colors-dark-bold-green-12,
        content-teaser-border-color:$primary-colors-solids-bold-green,
        content-teaser-background:  $interaction-colors-dark-coral-12,
        content-teaser-hover-background: $interaction-colors-dark-coral-20,
        content-teaser-hover-text: $primary-colors-solids-bold-green,
        accordion-hover-border-color: $primary-colors-solids-bold-green,
        accordion-hover-background-color: $interaction-colors-dark-bold-green-12,
        request-border-color: $primary-colors-solids-bold-green,
        user-menu-background-color: $interaction-colors-dark-bold-green-12,
        user-menu-item-background-color: $interaction-colors-dark-coral-8,
        setting-description-color: #ffffffd4,
        modal-background-color:$interaction-colors-dark-bold-green-12,
        hero-background: linear-gradient(180deg,#000000b3 1.45%,#0000004d 101.45%),
        tag-border-color: $interaction-colors-dark-interactive-coral,
        tag-hover-color: $primary-colors-solids-bold-green,
        jit-welcome-background: $interaction-colors-dark-coral-8,
        star-color: $feedback-colors-dark-yellow,
        list-label-color: $secondary-colors-deep-blue-10,
        list-border-color:  $secondary-colors-deep-blue-70,
        survey-success-icon-color: $primary-colors-solids-bold-green

    )
);


  /*
   * Implementation of themes
   */
   @mixin themify($themes) {
    @each $theme, $map in $themes {
          
      .theme-#{$theme} & {
        $theme-map: () !global;
        @each $key, $submap in $map {
          $value: map-get(map-get($themes, $theme), '#{$key}');
          $theme-map: map-merge($theme-map, ($key: $value)) !global;
        }
        @content;
        $theme-map: null !global;
      }

      &.theme-#{$theme} {
        $theme-map: () !global;
        @each $key, $submap in $map {
          $value: map-get(map-get($themes, $theme), '#{$key}');
          $theme-map: map-merge($theme-map, ($key: $value)) !global;
        }
  
        @content;
        $theme-map: null !global;
      }

      :global(.theme-#{$theme}) & {
        $theme-map: () !global;
        @each $key, $submap in $map {
          $value: map-get(map-get($themes, $theme), '#{$key}');
          $theme-map: map-merge($theme-map, ($key: $value)) !global;
        }
  
        @content;
        $theme-map: null !global;
      }
    }
  }
  
  @function themed($key) {
    @return map-get($theme-map, $key);
  }