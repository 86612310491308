@import "theme";
@import "variables";

.form-floating>.form-select~label,
.form-floating>.form-control:not(:placeholder-shown)~label{
    font-size: 13px;
    line-height: 20px;
    opacity: 1 !important;
    padding-left: 22px !important;
}


.form-floating>.form-control:not(:placeholder-shown) {
    // border-bottom: 1px solid $secondary-colors-deep-blue-30;
    color: $primary-colors-solids-white;
}


.form-floating>.form-control-plaintext~label, 
.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
    color: $secondary-colors-deep-blue-30;
}

.form-floating>.form-control-plaintext~label::after, 
.form-floating>.form-control:focus~label::after, .form-floating>.form-control:not(:placeholder-shown)~label::after, .form-floating>.form-select~label::after {
    background-color: transparent !important;
}


.input-group>.form-control.is-invalid~ button, 
.input-group>.was-validated~ button 
.input-group>.form-control:invalid~ button{
    border-bottom: 1px solid var(--bs-form-invalid-border-color);

    svg{
        fill: var(--bs-form-invalid-border-color);
    }
}


.sie-floating-input {

    input {
        // color: $secondary-colors-deep-blue-30;
        color: $primary-colors-solids-white;
        background-color: $interaction-colors-dark-coral-12;

        border: 0;
        border-radius: 0;
        border-bottom: 1px solid $secondary-colors-deep-blue-30;

        &:not(.custom-focus):focus {
            outline: 1px solid $color-focus;
            outline-offset: 2px;

            color: $secondary-colors-deep-blue-30;
            background-color: $interaction-colors-dark-bold-green-12;
            border-bottom: 1px solid $primary-colors-solids-bold-green;

            &+label {
                color: $primary-colors-solids-bold-green !important;
                font-size: 13px;
                line-height: 20px;
                opacity: 1 !important;
                padding-left: 22px !important;

                &:after {
                    background-color: transparent !important;
                }
            }


            &:active+label {
                color: $primary-colors-solids-bold-green !important;
                font-size: 13px;
                line-height: 20px;
                opacity: 1 !important;
                padding-left: 22px !important;

                &:after {
                    background-color: transparent !important;
                }
            }
        }

        // &:hover {
        //     background-color: $interaction-colors-dark-bold-green-12;
        //     color: $primary-colors-solids-white;
        //     border-bottom: 1px solid $primary-colors-solids-bold-green;

        //     &+label {
        //         color: $primary-colors-solids-white;
        //     }

            
        // }
    }

    &:hover {
        label {
            color: $primary-colors-solids-white;
        }

        input{
            background-color: $interaction-colors-dark-bold-green-12;
            color: $primary-colors-solids-white;
            border-bottom: 1px solid $primary-colors-solids-bold-green;
        }

        button {
            background-color: $interaction-colors-dark-bold-green-12;
            color: $primary-colors-solids-white;
            border-bottom: 1px solid $primary-colors-solids-bold-green;
        }
    }

    &:active+label {
        color: $primary-colors-solids-bold-green !important;
    }

    label {
        color: $secondary-colors-deep-blue-30;
        padding-left: 22px !important;
        width: 100%;
    }

    button {
        background-color: $interaction-colors-dark-coral-12;
        border-bottom: 1px solid $secondary-colors-deep-blue-30;
        border-top: none;
        border-left: none;
    }

    &:focus-within {


        // &>[type="password"] {
        &>input.custom-focus {
            border-width: 1px 0 1px 1px;
            border-style: solid;
            border-color: $color-focus;
            outline-offset: 2px;

            color: $secondary-colors-deep-blue-30;
            background-color: $interaction-colors-dark-bold-green-12;
            border-bottom: 1px solid $primary-colors-solids-bold-green;

            box-shadow: none;

            &+label {
                color: $primary-colors-solids-bold-green !important;
                font-size: 13px;
                line-height: 20px;
                opacity: 1 !important;
                padding-left: 22px !important;

                &:after {
                    background-color: transparent !important;
                }
            }


            &:active+label {
                color: $primary-colors-solids-bold-green !important;
                font-size: 13px;
                line-height: 20px;
                opacity: 1 !important;
                padding-left: 22px !important;

                &:after {
                    background-color: transparent !important;
                }
            }
        }

        &>button.custom-focus {

            border-width: 1px 1px 1px 0;
            border-style: solid;
            border-color: $color-focus;
            outline-offset: 2px;
            border-bottom: 1px solid #00FFB9 !important;

            background-color: $interaction-colors-dark-bold-green-12;

        }
    }



}

.sie-table-input {
    color: $secondary-colors-deep-blue-30;
    background-color: $interaction-colors-dark-coral-12;

    height: 24px;
    font-size: small;

    border: 0;
    border-radius: 0;
    border-bottom: 1px solid $secondary-colors-deep-blue-30;

    &:focus {
        outline: 1px solid $color-focus;
        outline-offset: 2px;

        color: $secondary-colors-deep-blue-30;
        background-color: $interaction-colors-dark-bold-green-12;
        border-bottom: 1px solid $primary-colors-solids-bold-green;
    }
}


.sie-floating-select {
    // color: $secondary-colors-deep-blue-30;
    color: $secondary-colors-deep-blue-30;
    background-color: $interaction-colors-dark-coral-12;

    border: 0;
    border-radius: 0;
    border-bottom: 1px solid $secondary-colors-deep-blue-30;

    &:hover {
        color: $primary-colors-solids-white;
        border-bottom: 1px solid $primary-colors-solids-bold-green;
    }

    &:focus {
        outline: 1px solid $color-focus;
        outline-offset: 2px;

        color: $primary-colors-solids-bold-green;
        background-color: $interaction-colors-dark-bold-green-12;
        border-bottom: 1px solid $primary-colors-solids-bold-green;
    }
   

    &:disabled{
        background-color: $interaction-colors-dark-coral-12;
        color: $secondary-colors-deep-blue-55!important;
    }

    option{
            
        color: $primary-colors-solids-white;

        &:hover {
            background-color: $interaction-colors-light-teal!important;
        }
    }
}
